import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import Layout from '../components/layout'
import Post from '../components/post'
import Navigation from '../components/navigation'

import Info from "../components/info"

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCodeBranch} from "@fortawesome/free-solid-svg-icons";
import { faGithubSquare } from '@fortawesome/free-brands-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(
  faCodeBranch,
  fab,
);

const Index = () => {
  return (
    <>
      <SEO />
      <Layout>
      <Info/>
      </Layout>
    </>
  )
}

export default Index

import React from "react"
import style from '../styles/index.module.css'
import HeadlineWithFocus from '../components/HeadlineWithFocus.js';

import {
  FaTwitter as Twitter,
  FaGithub as Github,
  FaYoutube as Youtube,
  FaInstagram as Insta,
  FaEnvelope as Mail,
  FaFile as CiVi,
} from "react-icons/fa"

export default function info() {
  return (
    <div className="container">
      <HeadlineWithFocus>
        <div className={style.display_main}>
          <span style={{ color: "gray" }}>Hello, I'm</span> <br />
          <span style={{ color: "#FF8A00" }}>Achilleas</span>
        </div>
      </HeadlineWithFocus>
      <div className={style.display_with}> return {"{"}</div>
      <div className={style.text_in}>
        Machine Learning
        <br />
        Computer Vision
        <br />
        Health
        <br />
        AgriTech
        <br />
        Computational Biology
      </div>
      <div className={style.display_with}>{"}"}</div>
      <div className="h1 mt-5">
        <a className={style.icon} href="https://github.com/Achilleas">
          <Github />
        </a>
        <a className={style.icon} href="mailto://geo.achilleas@gmail.com">
          <Mail />
        </a>
        <a className={style.icon} href="https://www.instagram.com/achilleas.georgiou/">
          <Insta />
        </a>
        <a className={style.icon} href="https://drive.google.com/file/d/1H9jCZFzc8U830aDBsNHIRwDGZ6oGKDN7/view">
          <CiVi />
        </a>

      </div>
    </div>
  )
}
